import React from 'react'
import * as firebase from 'firebase';


class GetInput extends React.Component{
    constructor(){
        super()
        this.state= {
            status: 1,
            games: {},
            gameKeys: [],
            selectedGame: "",
            role: "",
        }
        this.goStateHigher=this.goStateHigher.bind(this)
        this.changeState=this.changeState.bind(this)
        this.goStateLower=this.goStateLower.bind(this)
    }

    componentDidMount() {
        this.firebaseRequest=firebase.database().ref("programs").on("value", (snapshot)=> {
            this.setState({
                games:snapshot.val(),
                gameKeys:Object.keys(snapshot.val())
            })
        })
        if(this.props.data.selectedGame !== ""){
            this.setState({status:2,selectedGame:this.props.data.selectedGame})
        }
    }

    componentWillUnmount() {
        firebase.database().ref("programs").off("value",this.firebaseRequest)
    }

    changeState(data){
        this.setState(data)
    }

    goStateHigher(name,item){
        var execute=(()=>{
            if(item!==""&&name!=="") {
                this.setState({[name]:item})
                if (this.state.status===2){
                    var newState=this.state
                    newState[name]=item
                    this.props.nextFunc(newState,1)
                }else{
                    this.setState(oldState=>{{status:oldState.status+=1}})
                }

            }
        })
        return execute
    }

    goStateLower(){
        if(this.state!==1) {
            this.setState(oldState=>{return {status:oldState.status-1}})
        }
    }


    render() {
        return(
            <div  className="full">

                {
                //code for fase 1
                this.state.status===1&&(
                    <div className="grid full">
                        <div className="gameItems">
                            {this.state.gameKeys.map((item, index)=>{
                                return (
                                <div className="gameItem" key={index} onClick={this.goStateHigher("selectedGame",item)}>

                                    <img src={this.state.games[item].displayImage ||"https://firebasestorage.googleapis.com/v0/b/join-and-create-system.appspot.com/o/Tekengebied%205.png?alt=media&token=a60de470-652a-4c31-8701-fc1d49bcb8d8"}/>
                                    <p>{this.state.games[item].displayName}</p>

                                </div>
                                )
                            }
                            )
                            }
                        </div>
                    </div>
                )}

                {
                this.state.status === 2 &&(
                    <div>
                        <p onClick={this.goStateHigher("role","creator")}>Create Party</p>
                        <p onClick={this.goStateHigher("role","player")}>Join Party</p>
                    </div>
                    )
                }
                {this.state.status!==1&&(
                <div>
                    <button onClick={this.goStateLower}>Back</button>
                </div>)}
            </div>
        )
    }
}

export default GetInput