import React from 'react'
import * as firebase from "firebase";

class ServerForm extends React.Component{
    constructor(){
        super()
        this.changeHandler=this.changeHandler.bind(this)
        this.submit=this.submit.bind(this)

        this.state={
            role:"",
            config:{},
            fieldsOrder:[],
            fields:{},
            showSubmit:false,
            gameDatabase:null,
            Code:"",
            errors:{}
        }
    }

    changeHandler(event){
        var {name,value,type,checked}=event.target
        if(type === "checkbox"){
            value=checked
        }else if(type==="number"){
            value= parseFloat(value)
        }

        this.setState({[name]:value},()=>{
            if(name==="Code") {
                this.state.gameDatabase.database().ref("/party/codes").off("value")
                this.state.gameDatabase.database().ref("/party/codes").on("value",snapshot=> {
                try{
                    var codeList=Object.keys(snapshot.val())
                    var showSubmit
                    if (
                        codeList.some((value1 => {return value1===this.state.Code}))
                    ){
                        showSubmit=true
                    }else{
                        showSubmit=false
                    }
                    if(this.state.role==="creator"){
                        showSubmit= !showSubmit
                    }
                    this.setState({showSubmit:showSubmit})
                }catch (e) {
                    if (this.state.Code!==null){
                        this.setState({showSubmit:true})
                        this.setError({code:"This field is empty"})
                    }
                }finally {
                    if (this.state.Code===null){
                        this.setState({showSubmit:false})
                        this.setError({code:"This field is empty"})
                    }
                }
                })
            }
            
        })


    }

    setError(field,error){
        this.setState(oldState=>{
        var errors=oldState.errors
        Object.assign(errors,{field:error})
        return {errors:errors}
        })
    }
    componentDidMount() {
        firebase.database().ref("/programs/").child(this.props.data.selectedGame).once("value",(snapshot) =>{
            var value=snapshot.val()
            if(this.props.data.gameDatabases[this.props.data.selectedGame]===undefined){
                var gameDatabases= firebase.initializeApp(value.config,this.props.data.selectedGame)
                this.props.addFunc(this.props.data.selectedGame,gameDatabases)
            }else{
                var gameDatabases=this.props.data.gameDatabases[this.props.data.selectedGame]
            }
            var emptyField=value.emptyField
            var snapKeyList=Object.keys(emptyField)
            snapKeyList.map(value => {
                var item=emptyField[value]
                this.setState({[value]:item.defaultValue})
            })

            this.setState({
                config:value.config,
                fieldsOrder:value.fieldsOrder.split(","),
                fields:value.inputFields,
                gameDatabase:gameDatabases
            })
        })

        firebase.database().ref("program/"+this.props.data.selectedGame).once("value", snapshot=> {
            console.log(snapshot.val())


        })

        var role=this.props.data.role
        var showSubmit=false
        this.setState({role:role,showSubmit:showSubmit})


    }



    submit(){
        this.state.gameDatabase.database().ref("/party/codes").off("value")
        var secondDatabase=this.state.gameDatabase
        var partyRoot=secondDatabase.database().ref("party/codes")

        var pushen=partyRoot.child(this.state.Code)
        var key=pushen.key
        this.setState({key:key})

        firebase.database().ref(`programs/${this.props.data.selectedGame}/emptyField`).once("value", snapshot1=> {
            var snapKeyList=Object.keys(snapshot1.val())
            var fieldsOrder = this.state.fieldsOrder.concat(snapKeyList)
            var startFields=this.state.fields
            Object.assign(startFields,snapshot1.val())
            var fields=startFields

            console.log(fieldsOrder)
            console.log(fields)


            var globals = {}
            var player = {}
            var errors = {}
            pushen.child("users").once("value", snapshot => {

                fieldsOrder.map(value => {
                    var fieldData = fields[value]


                    if (fieldData.role.includes(this.state.role)) {
                        if (fieldData.global) {
                            globals[value] = this.state[value]
                        }

                        if (fieldData.personData) {
                            player[value] = this.state[value]
                        }

                        console.log(fieldData)
                        if (fieldData.required) {
                            if (this.state[value] === undefined || this.state[value] === null) {
                                errors[value] = "This field is required"
                            }
                        }
                        if (value === "Naam") {
                            if (Object.keys(snapshot).some(value1 => value1 === this.state.name)) {
                                errors[value] = "Name is already taken"
                            }
                        }
                    }


                })

                if (Object.keys(errors).length === 0) {

                    try {
                        console.log(player)
                        console.log(global)


                        pushen.child("globals").update(globals)
                        pushen.child("players").child(this.state.Naam).update(player)
                    } catch (e) {
                        throw e
                    } finally {
                        var code = key.toString()
                        console.log(code)
                        this.setState({code: code})
                        this.props.nextFunc({code: code}, 1)
                    }

                } else {
                    this.setState({errors: errors})
                }

            })
        })
    }

    render() {
        return(
            <div>
                <form>
                {
                    this.state.fieldsOrder.map((value, index) => {
                        var fieldData=this.state.fields[value]
                        if(fieldData.role.includes(this.state.role)){
                            return (<span>
                                        <p key={index}>{value.replace("_"," ")}:<input  value={this.state[value]} name={value} type={fieldData.type} onChange={this.changeHandler}/></p>
                                        <p>{this.state.errors[value]}</p>
                                    </span>
                                    )
                        }
                        return null
                    })
                }
                </form>
                <button onClick={()=>this.props.nextFunc({},-1)}>back</button>
                <button style={{display:this.state.showSubmit?"":"none"}} onClick={this.submit}>{this.state.role ==="player" ? "Join Party" : "Create Party" }</button>
            </div>
        )
    }
}

export default ServerForm