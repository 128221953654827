import React from 'react';
import './App.css';
import GetInput from "./Components/GetInput";
import ServerForm from "./Components/ServerFrom";
import WaitingLobbyCreator from "./Components/WaitingLobbyCreator";
import WaitingLobbyPlayer from "./Components/WaitingLobbyPlayer"

import * as firebase from "firebase";


class App extends React.Component{
  static POSSIBLESTATES={
    GETTINGINFO:1,
    SERVERFORM:2,
    WAITINGLOBBY:3
  }


  constructor(){
    super()

    this.changeState= this.changeState.bind(this)
    this.addGameDatabase=this.addGameDatabase.bind(this)
    this.state={
      contentIndex: App.POSSIBLESTATES.GETTINGINFO,
      selectedGame:"",
      role:"",
      code:"",
      gameDatabases:{}
    }
  }

  addGameDatabase(name,database){
    this.setState(oldState=>{
      var gameDatabases=oldState.gameDatabases
      gameDatabases[name]=database
      return {gameDatabases:gameDatabases}
    })
  }

  changeState(data, amount){
    if(amount=== undefined){
      amount=1
    }
    if(this.state.contentIndex+amount < 4){
      if(this.state.contentIndex===1){
        if(data.role=== "creator"||data.role==="player"){
          this.setState(data)
        }else{
          console.log(data)
          console.log(this.state)
          throw new Error("Data is not correct")
        }
      }else if(this.state.contentIndex===2){
          this.setState(data)

      }
      console.log("amount:"+(this.state.contentIndex+amount))
      this.setState(prevstate=>{return {contentIndex:prevstate.contentIndex+amount}} )
    }
  }



  render() {
    return(
        <div className="full">
          {this.state.contentIndex === App.POSSIBLESTATES.GETTINGINFO  && <GetInput data={this.state} nextFunc={this.changeState}/> }
          {this.state.contentIndex === App.POSSIBLESTATES.SERVERFORM   && <ServerForm  data={this.state} nextFunc={this.changeState} addFunc={this.addGameDatabase}/> }
          {this.state.contentIndex === App.POSSIBLESTATES.WAITINGLOBBY &&
              (
              this.state.role==="creator" ?
              <WaitingLobbyCreator data={this.state}/> :
              <WaitingLobbyPlayer data={this.state}/>
              )
          }
          </div>
    )
  }
}

export default App;
