import React from 'react'
class WaitingLobbyCreator extends React.Component{
    constructor(){
        super()
        this.state={
            code:null,
            players:[],
            partyRef:{}
        }
    }

    componentDidMount() {
        var code=this.props.data.code
        var secondDatabase=this.props.data.gameDatabases[this.props.data.selectedGame]
        var partyRef=secondDatabase.database().ref("/party/codes/"+code)
        this.setState({code:code,secondDatabase:secondDatabase,partyRef:partyRef})
        partyRef.child("players").on("value", a => {
            this.setState({players:Object.keys(a.val())})
        })


    }

    render() {
        return(
            <div>
                <p onClick={this.props.onClick}>{this.state.code}</p>
                <div>
                    {this.state.players.map(value=>{
                       return <p>{value}</p>
                    })}
                </div>
            </div>
        )
    }
}

export default WaitingLobbyCreator